<template>
  <v-card class="" dense elevation="0">
    <v-card-title>
      <v-btn icon @click="onCancel">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span class="headline">{{ title }}</span>
    </v-card-title>

    <validation-observer ref="observer" v-slot="{ invalid }">
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|min:1"
                      :name="$t('materials.part-id')"
                    >
                      <v-text-field
                        ref="partid"
                        v-model="item.part_id"
                        :label="$t('materials.part-id')"
                        dense
                        :error-messages="errors"
                        @keydown.esc="onCancel"
                        @focus="keyboardField = 'part-id'"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      :rules="{ required: true, numeric: true, min_value: 0 }"
                      :name="$t('materials.quantity')"
                    >
                      <v-text-field
                        ref="name"
                        v-model="item.quantity"
                        :label="$t('materials.quantity')"
                        dense
                        :error-messages="errors"
                        @keydown.esc="onCancel"
                        @focus="keyboardField = 'quantity'"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col>
              <date-picker
                v-model="item.delivery_date"
                :label="$t('materials.delivery-date')"
                :clearable="true"
                @focus="keyboardField = 'delivery-date'"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|min:1"
                      :name="$t('materials.location')"
                    >
                      <v-text-field
                        v-model="item.location"
                        :label="$t('materials.location')"
                        dense
                        :error-messages="errors"
                        @keydown.esc="onCancel"
                        @focus="keyboardField = 'location'"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      :rules="{ decimal: true }"
                      :name="$t('materials.value')"
                    >
                      <v-text-field
                        v-model="item.value"
                        :label="$t('value')"
                        type="number"
                        dense
                        :error-messages="errors"
                        @keydown.esc="onCancel"
                        @focus="keyboardField = 'value'"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <validation-provider
                v-slot="{ errors }"
                rules="required|min:1"
                :name="$t('currency')"
              >
                <v-select
                  v-model="item.currency"
                  :items="currenciesToSelect"
                  :menu-props="{ maxHeight: '400' }"
                  :label="$t('currency')"
                  :hint="$t('select-hint')"
                  persistent-hint
                  dense
                  :error-messages="errors"
                  @keydown.esc="onCancel"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="onCancel()">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          :disabled="!isNew && invalid"
          text
          @click="onSave()"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import MaterialService from '@/services/MaterialService.js';
import CurrencyService from '@/services/CurrencyService.js';
import DatePicker from '@/components/base/DatePicker.vue';
import Mixin from './materialsMixin';

export default {
  components: {
    DatePicker
  },
  mixins: [Mixin],
  props: {
    part: {
      type: Object,
      required: true
    }
  },
  emits: ['update:material:part'],
  data() {
    return {
      item: { ...this.part },
      currencies: []
    };
  },
  computed: {
    title() {
      if (this.isNew) {
        return this.$t('materials.new-part');
      }
      return this.$t('materials.edit-part');
    },
    isNew() {
      return !(this.item.id > 0);
    },
    currenciesToSelect() {
      return this.currencies.map((c) => {
        return {
          text: c.symbol,
          value: c.symbol
        };
      });
    }
  },

  watch: {
    part: {
      handler(val) {
        this.item = { ...val };
        this.$refs.observer.reset();
      },
      deep: true
    }
  },
  async activated() {
    await this.init();
    this.focusInput();
  },
  methods: {
    focusInput() {
      console.log('focusInput');
      this.$nextTick(() => {
        this.$refs.partid.focus();
      });
    },

    async init() {
      await this.loadCurrencies();
    },

    async loadCurrencies() {
      try {
        this.currencies = await CurrencyService.getCurrencies();
        // this.currencies = currencies.map((currency) => currency.symbol);
      } catch (err) {
        this.showError(this, err);
      }
    },

    async onCancel() {
      this.initialized = false;
      this.$refs.observer.reset();
      this.$emit('list:materials:part');
    },

    async onSave() {
      const result = await this.$refs.observer.validate();
      if (!result) return;

      console.log('part save: ', this.item);
      try {
        let obj = { ...this.item };
        if (obj.delivery_date) {
          obj.delivery_date = this.item.delivery_date
            .toISOString()
            .substr(0, 10);
        }

        if (this.isNew) {
          let result = await MaterialService.PostPart(obj);
          console.log('result: ', result);
          this.item = Object.assign({}, result.data);
          console.log('added part: ', this.item);

          this.$emit('add:material:part', this.item);
          this.$root.$emit('add:material:part', this.item);
          this.$emit('list:materials:part');
        } else {
          let result = await MaterialService.patchPart(obj);
          console.log('result: ', result);
          Object.assign(this.item, result);

          this.$emit('update:material:part', this.item);
          this.$root.$emit('update:material:part', this.item);

          this.$emit('list:materials:part');
        }
      } catch (err) {
        this.showError(this, err);
      }
    }
  }
};
</script>

<style></style>
