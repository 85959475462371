<template>
  <v-card elevation="0">
    <v-data-table
      dense
      :loading="loading"
      :loading-text="$t('loading')"
      :headers="columns"
      :items="items"
      :footer-props="{
        'items-per-page-options': [20, 40, 60, 80, 100],
        'items-per-page-text': $t('table.per-page')
      }"
      :no-data-text="$t('table.no-data')"
      :items-per-page="20"
      :search="search"
      :options.sync="options"
      :server-items-length="total"
      :single-expand="true"
      :expanded.sync="expanded"
      show-expand
    >
      <template #top>
        <v-toolbar flat>
          <v-col>
            <v-row>
              <v-chip
                v-for="item in filtersWithLabels"
                :key="item.field"
                class="ma-2 secondary"
                close
                label
                text-color="white"
                @click:close="onFilterClose(item)"
              >
                <v-icon left> mdi-filter </v-icon>
                {{ item.label }}
              </v-chip>
              <v-col cols="3" sm="4" md="4">
                <v-switch
                  v-model="showArchived"
                  :label="$t('materials.show-archived')"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" sm="5" md="4">
            <v-row align-self="end">
              <v-col class="text-right">
                <v-btn
                  v-if="hasPermission('api.add_materialpart')"
                  color="primary"
                  dark
                  class="mb-2 mr-2"
                  @click="onNewItem"
                >
                  {{ $t('new') }}
                  <v-icon right dark> mdi-plus </v-icon>
                </v-btn>
                <v-btn color="primary" dark class="mb-2" @click="onExport">
                  {{ $t('export') }}
                  <v-icon right dark> mdi-file-excel </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-toolbar>
      </template>

      <template #[`item.delivery_date`]="{ item }">
        <span>{{ date_to_str(item.delivery_date) }}</span>
      </template>

      <template #[`item.value`]="{ item }">
        <span>{{ value_str(item) }}</span>
      </template>

      <template #[`item.actions`]="{ item }">
        <v-icon
          v-if="hasPermission('api.change_materialpart')"
          small
          class="mr-2"
          @click="onEditItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon small class="mr-2" @click="onPrintLabel(item)">
          mdi-printer
        </v-icon>
        <v-icon
          v-if="hasPermission('api.delete_materialpart') && !item.archived"
          small
          @click="toggleArchive(item)"
        >
          mdi-archive
        </v-icon>
        <v-icon
          v-if="hasPermission('api.delete_materialpart') && item.archived"
          small
          @click="toggleArchive(item)"
        >
          mdi-restore
        </v-icon>
        <v-icon
          v-if="
            hasPermission('api.delete_materialpart') && item.orders.length === 0
          "
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <more-info :item="item" />
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import MaterialService from '@/services/MaterialService.js';
import materialsMixin from './materialsMixin';

export default {
  name: 'PartList',
  components: {
    moreInfo: () => import('./partInfo.vue')
  },
  mixins: [materialsMixin],
  props: {
    search: {
      type: String,
      required: false,
      default: ''
    },
    material: {
      type: Object,
      required: true
    },
    filters: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      searchTimeout: null,
      loading: false,
      showArchived: false,

      items: [],
      total: 0,
      selected: [],

      editedIndex: -1,
      editedItem: {},
      editStatusInline: null,
      defaultItem: {},

      expanded: [],
      filterList: [...this.filters],
      options: {}
    };
  },
  computed: {
    columns() {
      return [
        {
          text: this.$t('materials.name'),
          value: 'material_ro.name',
          sortable: true
        },
        {
          text: this.$t('materials.type'),
          value: 'material_ro.type',
          sortable: true
        },
        {
          text: this.$t('materials.part-id'),
          value: 'part_id',
          sortable: true
        },
        {
          text: this.$t('materials.quantity'),
          value: 'quantity',
          sortable: true
        },
        {
          text: this.$t('materials.delivery-date'),
          value: 'delivery_date',
          sortable: true
        },
        {
          text: this.$t('materials.location'),
          value: 'location',
          sortable: true
        },
        {
          text: this.$t('value'),
          value: 'value',
          sortable: true
        },
        {
          text: this.$t('actions'),
          value: 'actions',
          sortable: false
        }
      ];
    },

    filtersWithLabels() {
      console.log('filtersWithLabels:', this.options);
      if (!this.filterList) return [];

      return this.filterList.map((f) => {
        return {
          ...f,
          label: `${this.$t(f.label)} ${f.operation} ${f.value}`
        };
      });
    }
  },

  watch: {
    options: {
      async handler() {
        console.log('#options:', this.options);
        await this.loadItems();
      },
      deep: true
    },
    search: {
      handler() {
        console.log('#search');
        this.options.page = 1;
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.loadItems();
        }, 500);
      }
    },
    filters: {
      async handler(val) {
        console.log('#filters:', val);
        if (!val) return;
        this.options.page = 1;
        this.filterList = [...val];
        await this.loadItems();
      },
      deep: true
    },
    showArchived: {
      async handler(val) {
        console.log('showArchived:', val);
        this.options.page = 1;
        await this.loadItems();
      }
    }
  },

  mounted() {
    this.$root.$on('add:material:part', this.onItemAdded);
  },
  beforeDestroy() {
    this.$root.$off('add:material:part', this.onItemAdded);
  },
  methods: {
    async loadItems() {
      this.loading = true;

      const options = this.prepareOptions();
      try {
        [[this.items, this.total]] = await Promise.all([
          MaterialService.GetParts(options, this.search)
        ]);

        // console.log('parts: ', this.items);
      } catch (err) {
        this.showError(this, err);
      } finally {
        this.loading = false;
      }
    },

    prepareOptions() {
      const options = { ...this.options, filters: this.filterList };
      if (!this.showArchived) {
        options.archived = false;
      }
      console.log('options=', options);
      return options;
    },

    onEditItem(item) {
      console.log('edit part:', item);
      this.$emit('edit:material:part', item);
    },

    async onPrintLabel(item) {
      console.log('print label:', item);
      try {
        const pdfData = await MaterialService.GetPartLabelPdf(item);
        const blob = new Blob([pdfData], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        link.target = '_blank'; // Open the new window
        link.click();

        window.URL.revokeObjectURL(link.href);
      } catch (err) {
        this.showError(this, err);
      }
    },

    onItemAdded(item) {
      console.log('onItemAdded:', item);
      console.log('this.items:', this.items);
      this.items.push(item);
      this.total++;
    },

    async toggleArchive(item) {
      console.log('archive part:', item);
      try {
        const archived = item.archived;
        let result = await MaterialService.ArchivePart(item, !archived);
        item.archived = result.archived;
        console.log('result: ', result);
        if (!this.showArchived) {
          const idx = this.items.indexOf(item);
          this.items.splice(idx, 1);
        }
      } catch (err) {
        this.showError(this, err);
      }
    },

    async deleteItem(item) {
      console.log('delete part:', item);
      const res = await this.$swal({
        html: this.$t('materials.part-delete-confirmation'),
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      });

      if (res.isDismissed) {
        return;
      }

      const idx = this.items.indexOf(item);

      try {
        let result = await MaterialService.DeletePart(item);
        console.log('result: ', result);
        this.items.splice(idx, 1);
      } catch (err) {
        this.showError(this, err);
      }
    },

    onNewItem() {
      this.$emit('new:material:part', this.material);
    },

    onFilterClose(item) {
      this.filterList = this.filterList.filter((f) => f.field != item.field);

      if (this.filterList.length == 0) {
        this.$emit('list:materials:parts');
      }
    },

    date_to_str(date) {
      if (!date) return '';
      return date.toLocaleDateString();
    },

    value_str(item) {
      if (!item.value || item.value == 0) return '';
      if (item.currency == 'USD') {
        return `$${item.value}`;
      }
      if (item.currency == 'EUR') {
        return `€${item.value}`;
      }
      return `${item.value} ${item.currency}`;
    },

    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item];
    },

    async onExport() {
      const options = this.prepareOptions();
      await MaterialService.parts_excel(
        this.material.name,
        options,
        this.search
      );
    }
  }
};
</script>

<style lang="sass"></style>
