<template>
  <v-card class="" dense elevation="0">
    <v-card-title>
      <v-btn icon @click="onCancel">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span class="headline">{{ $t('materials.report') }}</span>
    </v-card-title>
    <v-row>
      <v-col cols="6" sm="3" md="3">
        <date-picker
          v-model="from"
          :label="$t('from')"
          @keydown.esc="onCancel"
        />
      </v-col>
      <v-col cols="6" sm="3" md="3">
        <date-picker v-model="to" :label="$t('to')" @keydown.esc="onCancel" />
      </v-col>
    </v-row>
    <v-row>
      <v-btn
        v-if="hasPermission('api.add_material')"
        color="primary"
        dark
        class="ml-4 mr-2"
        @click="onReport"
      >
        {{ $t('materials.report') }}
        <v-icon right dark> mdi-file-excel</v-icon>
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>
import DatePicker from '@/components/base/DatePicker.vue';
import MaterialService from '@/services/MaterialService.js';
export default {
  name: 'MaterialUsageReport',
  components: {
    DatePicker
  },
  data() {
    return {
      from: null,
      to: null
    };
  },
  computed: {},
  mounted() {},
  methods: {
    async onCancel() {
      this.initialized = false;
      //   this.$refs.observer.reset();
      this.$emit('list:materials');
    },
    async onReport() {
      console.log('onReport');
      if (!this.from || !this.to) {
        return;
      }

      try {
        this.from.setHours(0, 0, 0, 0);
        this.to.setHours(23, 59, 59, 999);
        const response = await MaterialService.report_of_materials_used(
          this.from.toISOString(),
          this.to.toISOString()
        );
        console.log(response);
      } catch (error) {
        console.log(error);
        await this.$swal({
          title: this.$t('material.report'),
          html: error,
          icon: 'error',
          confirmButtonText: this.$t('close')
        });
      }
    }
  }
};
</script>

<style scoped>
/* Your CSS goes here */
</style>
