import request from './request';

export default {
  getCurrencies() {
    let url = '/api/currencies/';

    return request.allPages(url, (url) => {
      return request.request(url, 'get', {}, {});
    });
  }
};
