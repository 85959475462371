import request from './request';
import mixin from '@/mixin.js';

export default {
  appendSearch(url, opts, search) {
    opts ||= {};
    let qp = [];
    if (search?.length > 0) {
      qp.push(`search=${search}`);
    }
    if (opts.archived !== undefined) {
      qp.push(`archived=${opts.archived}`);
    }

    if (opts.page) {
      qp.push(`page=${opts.page}`);
    }
    if (opts.itemsPerPage) {
      qp.push(`size=${opts.itemsPerPage}`);
    }
    if (opts.filters) {
      for (let filter in opts.filters) {
        let f = opts.filters[filter];
        qp.push(`${f.field}${f.operation}${f.value}`);
      }
    }
    let sort = [];
    if (opts.sortBy) {
      for (let i = 0; i < opts.sortBy.length; i++) {
        let sign = '';
        if (i < opts.sortDesc.length && opts.sortDesc[i]) {
          sign += '-';
        }
        let field = opts.sortBy[i];
        if (field === 'type') {
          field = 'type__name';
        }
        sort.push(sign + field);
      }
      if (sort.length > 0) {
        qp.push(`ordering=${sort.join(',')}`);
      }
    }

    let qps = qp.join('&');
    if (qps.length > 0) {
      url += '?' + qps;
    }

    return url;
  },

  transformMaterial(o) {
    return {
      ...o,
      created_at: mixin.methods.dateFromISO8601(o.created_at)
    };
  },

  transformMaterialPart(o) {
    return {
      ...o,
      created_at: mixin.methods.dateFromISO8601(o.created_at),
      delivery_date: mixin.methods.dateFromISO8601(o.delivery_date)
    };
  },

  async GetMaterialTypes(opts, search) {
    let url = `/api/material_types/`;
    url = this.appendSearch(url, opts, search);

    console.log('url:', url);
    let rsp = await request.request(url, 'get', {}, {});
    console.log(rsp.data.results);
    return rsp.data.results;
  },

  async GetMaterials(opts, search) {
    let url = `/api/materials/`;
    url = this.appendSearch(url, opts, search);

    let rsp = await request.request(url, 'get', {}, {});

    let items = rsp.data?.results;
    items = items.map(this.transformMaterial);

    return [items, rsp.data.count];
  },

  async GetParts(opts, search) {
    let url = `/api/material_parts/`;
    url = this.appendSearch(url, opts, search);

    let rsp = await request.request(url, 'get', {}, {});

    let items = rsp.data?.results;
    items = items.map(this.transformMaterialPart);

    return [items, rsp.data.count];
  },

  async PostMaterial(material) {
    // delete tool['client'];
    // delete tool['supplier'];
    console.log('post material: ', material);
    const result = await request.request(
      `/api/materials/`,
      'post',
      material,
      {}
    );

    // console.log("result: ", result);
    return this.transformMaterial(result);
  },

  async ImportMaterials(file) {
    let url = `/api/material_parts/import_materials/`;
    let req = new FormData();
    req.append('file', file);
    console.log('POST ', url);

    const rsp = await request.request(url, 'post', req);
    if (rsp['status'] !== 200) {
      console.log('Error while importing materials:', rsp);
      throw 'Error while importing materials; status_code=' + rsp['status'];
    }
    return rsp.data;
  },

  async GetPartLabelPdf(part) {
    console.log('get part label pdf: ', part.part_id);
    let id = part.id;
    const result = await request.request(
      `/api/material_parts/${id}/get_pdf_label`,
      'get_blob',
      {},
      {}
    );

    return result.data;
  },

  async PatchMaterial(material) {
    console.log('patch material: ', material);
    let id = material.id;
    const result = await request.request(
      `/api/materials/${id}/`,
      'patch',
      material,
      {}
    );

    // console.log("result: ", result);
    return this.transformMaterial(result);
  },

  async DeleteMaterial(material) {
    console.log('delete material: ', material);
    let id = material.id;
    const result = await request.request(
      `/api/materials/${id}/`,
      'delete',
      {},
      {}
    );

    console.log('result: ', result);
    return result;
  },

  async PostPart(part) {
    console.log('post material part: ', part);
    const result = await request.request(
      `/api/material_parts/`,
      'post',
      part,
      {}
    );

    // console.log("result: ", result);
    return this.transformMaterialPart(result);
  },

  async patchPart(part) {
    console.log('patch material part: ', part);
    let id = part.id;
    const result = await request.request(
      `/api/material_parts/${id}/`,
      'patch',
      part,
      {}
    );

    return this.transformMaterialPart(result.data);
  },

  async DeletePart(part) {
    console.log('delete material part: ', part);
    let id = part.id;
    const result = await request.request(
      `/api/material_parts/${id}/`,
      'delete',
      {},
      {}
    );

    console.log('result: ', result);
    return result;
  },

  async ArchivePart(part, archived) {
    console.log('archive material part: ', part);
    let id = part.id;
    const url = `/api/material_parts/${id}/archive/`;
    console.log('PUT ', url);

    const result = await request.request(
      url,
      'put',
      {
        archived: archived
      },
      {}
    );

    console.log('result: ', result);
    return result.data;
  },

  async materials_excel(opts, search) {
    const name = 'materials.xlsx';
    console.log('Generate ' + name);
    let url = `/api/materials/excel/`;
    url = this.appendSearch(url, opts, search);

    console.log('url:', url);
    await request.download(url, name);
  },

  async parts_excel(prefix, opts, search) {
    const name = prefix + '_parts.xlsx';
    console.log('Generate ' + name);
    let url = `/api/material_parts/excel/`;
    url = this.appendSearch(url, opts, search);

    console.log('url:', url);
    await request.download(url, name);
  },

  async report_of_materials_used(from, to) {
    const name = 'report_of_materials_used.xlsx';
    console.log('Generate ' + name);
    let url = `/api/materials/report_of_materials_used/`;
    url += `?from=${from}&to=${to}`;

    console.log('url:', url);
    await request.download(url, name);
  }
};
